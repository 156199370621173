import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/dark-logo.png";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../assets/icons/user.png";
import WhatsAppIcon from "../../../src/assets/icons/whatsapp3.png";
import "./style.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import BasketIcon from "../../assets/icons/bag.svg";
import HamIcon from '../../assets/icons/hamburger.png'
import PetGroom from '../../assets/sidebar/dog.svg';
import Vet from '../../assets/sidebar/veterinary.svg';
import Blogs from '../../assets/sidebar/blogging.svg';
import Food from '../../assets/sidebar/pet-food.svg';
import Product from '../../assets/sidebar/brand-identity.svg';
import Partner from '../../assets/sidebar/deal.svg';
import About from '../../assets/sidebar/list.svg';
import User from '../../assets/sidebar/user.svg';
import Booking from '../../assets/sidebar/booking.svg';
import Shampoo from '../../assets/sidebar/pet-shampoo.svg';

const Header = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playStoreAppStoreUrl, setPlayStoreAppStoreUrl] = useState();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [itemCount, setItemCount] = useState(0);


  useEffect(() => {
    // Retrieve item_count from localStorage when the component mounts
    const storedItemCount = localStorage.getItem('cart_item_count');
    if (storedItemCount) {
      setItemCount(storedItemCount);
    }
  }, []);

  useEffect(() => {
    if (/iPhone|iPod|iPad/.test(userAgent)) {
      setPlayStoreAppStoreUrl(
        "https://apps.apple.com/in/app/dearpet/id6449486111"
      );
    } else {
      setPlayStoreAppStoreUrl(
        "https://play.google.com/store/apps/details?id=com.dearpet.dear_pet_app"
      );
    }

    const loginData = JSON.parse(localStorage.getItem("loggedin"));
    setIsLogin(!!loginData);
  }, [userAgent]);

  const handleLoginClick = () => {
    navigate("/login", { state: { fromRegister: "header" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const handleLogout = () => {
    setIsModalOpen(false);
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        setIsLogin(false);
        window.scrollTo(0, 0);
        window.location.href = "/";
        Swal.fire("Logout Successfully!", {
          icon: "success",
        });
      }
    });
  };

  const moveProfile = () => {
    setIsModalOpen(false);
    navigate("/editProfile", { state: { frompage: "header" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };


  const onMyBookingClick = () => {
    setIsModalOpen(false);
    navigate("/mybookings");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const ByProduct = () => {
    setIsModalOpen(false);
    window.open("https://dearpetindia.com/", "_blank");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };
  const ByFood = () => {
    setIsModalOpen(false);
    navigate('/dearpet-foods')
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  const ByShampoo = () => {
    setIsModalOpen(false);
    navigate('/product/176');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  const handleHamburgerClick = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
    if (isModalOpen) {
      setIsModalOpen(false);
    }
  };

  const handleBackdropClick = () => {
    setIsHamburgerOpen(false);
    setIsModalOpen(false);
  };

  const handleMenuItemClick = (callback) => {
    callback();
    setIsHamburgerOpen(false);
  };



  // Additional navigation options
  const aboutus = () => {
    setIsModalOpen(false);
    navigate("/aboutus");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  // ------------------------------------------------------------------------------------------------------------
  const comingblogs = () => {
    setIsModalOpen(false);
    navigate("/blog/dear-pet");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const becomepartner = () => {
    setIsModalOpen(false);
    navigate("/vendor");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const vetathome = () => {
    setIsModalOpen(false);
    navigate("/vet");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const petgrooming = () => {
    setIsModalOpen(false);
    navigate("/grooming");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light border-bottom">
        <div className="purple-strip">
          <a href={playStoreAppStoreUrl} target="_blank" rel="noreferrer">
            <p className="strip-text mb-0 flex">
              Download the app and get upto 10% off
            </p>
          </a>
        </div>

        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" id="headerLogo" className="logo" />
          </a>

          <div className="header-right">
            <button className="whatsapp-icon-sticky">
              <a
                href="https://wa.me/+917599975888"
                target="_blank"
                rel="noreferrer"
              >
                <img src={WhatsAppIcon} alt="WhatsApp" />
              </a>
            </button>

            <div className="divider-vertical"></div>

            {!isLogin ? (
              <>
                <button onClick={handleLoginClick} className="user-icon">
                  <img
                    src={ProfileIcon}
                    alt="Profile"
                    className="profileIcon"
                  />
                </button>

                <div className="divider-vertical"></div>
              </>
            ) : (
              <>
                {(
                  <div className="cart-icon-container">
                    <img
                      src={BasketIcon}
                      alt="cart"
                      className="ms-2 basketIcon"
                      onClick={() => {
                        navigate("/myorder")
                      }}
                    />
                    {itemCount > 0 && (
                      <span className="cart-count">{itemCount}</span>
                    )}
                  </div>
                )}
                <div className="divider-vertical"></div>
              
              </>
            )}
            {/* <div className="divider-vertical"></div> */}

            <button className="hamburger-button" onClick={handleHamburgerClick} aria-label="Menu" >
              <img src={HamIcon} className="hamburger-icon-img" alt="menu icon" />
            </button>
            {isHamburgerOpen && (
              <div
                className="backdrop show"
                onClick={handleBackdropClick}
              ></div>
            )}
            <div
              className={`sliding-container ${isHamburgerOpen ? "open" : ""}`}
            >

              <p className="modal-link" onClick={() => handleMenuItemClick(petgrooming)}>
                <img src={PetGroom} className="sidemenu-icon" alt="Pet Grooming Icon" loading="lazy" /> Pet Grooming
              </p>
              <hr className="divider-horizontal" />

              <p className="modal-link" onClick={() => handleMenuItemClick(vetathome)}>
                <img src={Vet} className="sidemenu-icon" alt="Veterinary Icon" loading="lazy" /> Veterinary
              </p>
              <hr className="divider-horizontal" />

              <p className="modal-link" onClick={() => handleMenuItemClick(comingblogs)}>
                <img src={Blogs} className="sidemenu-icon" alt="Blogs Icon" loading="lazy" /> Blogs
              </p>
              <hr className="divider-horizontal" />

              <p className="modal-link" onClick={() => handleMenuItemClick(ByFood)}>
                <img src={Food} className="sidemenu-icon" alt="Food Icon" loading="lazy" /> Food
              </p>
              <hr className="divider-horizontal" />

              <p className="modal-link" onClick={() => handleMenuItemClick(ByShampoo)}>
                <img src={Shampoo} className="sidemenu-icon" alt="Food Icon" loading="lazy" /> Shampoo
              </p>
              <hr className="divider-horizontal" />

              {/* <p className="modal-link" onClick={() => handleMenuItemClick(ByProduct)}>
                <img src={Product} className="sidemenu-icon" alt="Product Icon" loading="lazy" /> Products
              </p>
              <hr className="divider-horizontal" /> */}

              <p className="modal-link" onClick={() => handleMenuItemClick(becomepartner)}>
                <img src={Partner} className="sidemenu-icon" alt="Partner Icon" loading="lazy" /> Become a Partner
              </p>
              <hr className="divider-horizontal" />

              <p className="modal-link" onClick={() => handleMenuItemClick(aboutus)}>
                <img src={About} className="sidemenu-icon" alt="About Us Icon" loading="lazy" /> About Us
              </p>
              <hr className="divider-horizontal" />


              {isLogin && (
                <div className="bottom-section">
                  {/* <hr className="divider-horizontal " /> */}
                  <p
                    className="modal-link light-text"
                    onClick={() => handleMenuItemClick(onMyBookingClick)}
                  >
                    <img src={Booking} className="sidemenu-icon" alt="Booking Icon" loading="lazy" /> My Bookings
                  </p>
                  <hr className="divider-horizontal" />
                  <p className="modal-link light-text" onClick={() => handleMenuItemClick(moveProfile)}>
                    <img src={User} className="sidemenu-icon" alt="User Icon" loading="lazy" /> My Profile
                  </p>

                  <button className="logout-btn" onClick={handleLogout}>
                    <p className="m-0 nav-link l-txt">Logout</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
