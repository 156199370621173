import React, { useState } from "react";
import "./style.css";
import Logo from "../../assets/images/dark-logo.png";

import FacebookIcon from "../../assets/images/Facebook.svg";
import InstagramIcon from "../../assets/images/Instagram.svg";
import LinkedinIcon from "../../assets/images/LinkedIn.svg";
import YoutubeIcon from "../../assets/images/YouTube.svg";
import EmailIcon from "../../assets/icons/email-icon.svg";
import PhoneIcon from "../../assets/icons/phone-icon.svg";

// import { saveNewsLetter } from "../../service/api";

import LocationNewIcon from "../../assets/icons/location-new-icon.svg";
import insta1 from '../../assets/images/insta1.webp';
import insta2 from '../../assets/images/insta2.webp';
import insta3 from '../../assets/images/insta3.webp';
import insta4 from '../../assets/images/insta4.webp';


const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setMessage('');
    // setError('');
    // try {
    //   const data = await saveNewsLetter({ email });
    //   if (data.message === "Email already exists.") {
    //     setError(data.message);
    //   } else {

    //     setMessage(data.message);
    //   }
    //   setEmail('');
    // } catch (error) {
    //   setError(error.message);
    // }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setMessage('');
    setError('');
  };

  return (
    <div className="footerContainer">
      <div className="footerTop">
        <div className="footerLogoDiv">
          <a className="navbar-brand my-4" href="/">
            <img src={Logo} alt="logo" id="footerLogo" className="logofooter" style={{ filter: "brightness(0) invert(1)", height: '30px' }} />
          </a>
          <div className="newsletterDiv my-4">
            <span className="newsletterDescription">Subscribe to our newsletter and never miss out on our exclusive updates and offers.</span>
          </div>
          <div className="my-4">

            <div className="masterInputText">
              <form className="inputContainer" onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="inputField"
                  value={email}
                  onChange={handleChange}
                  required
                />
                <button type="submit" className="submitButton">
                  <span>Subscribe</span>
                </button>
              </form>

            </div>
            {message && <div className="successMessage">{message}</div>}
            {error && <div className="errorMessage">{error}</div>}
          </div>
        </div>
        <div className="footerLinksDiv">
          <p className="m-0 contact-head mb-2">Menu</p>
          <a href="/" className="footerLink mx-1">Home</a><br></br>
          <a href="/aboutus" className="footerLink mx-1">About us</a><br></br>
          <a href="/t-policy" className="footerLink mx-1">Terms & Conditions</a><br></br>
          <a href="/rr-policy" className="footerLink mx-1">Return & Refund Policy</a><br></br>
          <a href="/s-policy" className="footerLink mx-1">Shipping Policy</a><br></br>
          <a href="/p-policy" className="footerLink mx-1">Privacy Policy</a>
        </div>
        <div className="footerInstagram" onClick={() => window.open('https://www.instagram.com/dearpet.in/', '_blank')}>
          <p className="m-0 contact-head text-center mb-2">Follow on Instagram</p>
          <div className="instagram-grid">
            <img src={insta1} alt="Photo 1" className="grid-photo" loading="lazy
            " />
            <img src={insta2} alt="Photo 2" className="grid-photo" loading="lazy
            " />
            <img src={insta3} alt="Photo 3" className="grid-photo" loading="lazy
            " />
            <img src={insta4} alt="Photo 4" className="grid-photo" loading="lazy
            " />
          </div>
        </div>

        <div className="footerContactUs">
          <p className="m-0 contact-head mb-2">Contact Us</p>
          <a href="tel:+917599975888" style={{ textDecoration: 'none', color: 'inherit' }}>
            <p className="m-0 contact-data">
              <img className="me-1" src={PhoneIcon} alt="phone-icon" /> +91 7599975888
            </p>
          </a>
          <a href="mailto:support@dearpet.in" style={{ textDecoration: 'none', color: 'inherit' }}>
            <p className="m-0 contact-data">
              <img className="me-1" src={EmailIcon} alt="email-icon" /> support@dearpet.in
            </p>
          </a>
          <a href="https://maps.app.goo.gl/NQbfn49SW3fHNkkCA" style={{ textDecoration: 'none', color: 'inherit' }}>
            <p className="m-0 contact-data">
              <img className="me-1" src={LocationNewIcon} alt="location-icon" /> A-2/ 145 A, Block C8, Keshav Puram, Tri Nagar, New Delhi, Delhi, 110035
            </p>
          </a>
        </div>



      </div>
      <div className="footerBottom">
        <div className="footerTextDiv my-2">
          <p className="footerBottomTxt">© 2024 Bark Buddies Pvt Ltd | <span className="fbt-txt">All Rights Reserved</span></p>
        </div>

        <div className="socialDiv my-2">
          <a href="https://www.facebook.com/dearpet.in/" target="blank">
            <img src={FacebookIcon} alt="facebook" />
          </a>
          {/* <a href="https://twitter.com/dearpetindia?s=21" target="blank">
            <img src={TwitterIcon} alt="twitter" />
          </a> */}
          <a href="https://www.instagram.com/dearpet.in/" target="blank">
            <img src={InstagramIcon} alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/dear-pet-india/" target="blank">
            <img src={LinkedinIcon} alt="linkedin" />
          </a>
          <a href="https://www.youtube.com/channel/UC28Md2tQWMsWE3-r-3drUvw" target="blank">
            <img src={YoutubeIcon} alt="youtube" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
