import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import React, { useEffect, useState } from "react";
import Footer from "./component/Footer/Footer";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import Header from "./component/Header/Header";

const firebaseConfig = {
  apiKey: "AIzaSyCY41jn0eEZMsfbNzEyZiLMwsS2r5yFT7k",
  authDomain: "dearpet-23e75.firebaseapp.com",
  projectId: "dearpet-23e75",
  storageBucket: "dearpet-23e75.appspot.com",
  messagingSenderId: "189601290532",
  appId: "1:189601290532:web:486e3895b3457163267bb1",
  measurementId: "G-JMG2W0LG5E"
};
const BecomingPartner = React.lazy(() => import("./pages/BecomeAPartner/BecomeAPartner"));

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

function App() {
  const subdomain = window.location.hostname.split('.')[0];


  useEffect(() => {
    analytics.logEvent("page_view");
  }, []);

  return (
    <div className="App">
       {subdomain == 'vendor' ? 
        <div>
          
          <BecomingPartner /> 
          <Footer />
        </div> :
      <BrowserRouter>
        {/* {load ? <Splash /> :<Main />} */}
        {/* <Header /> */}
        <Routes>
       
          <Route path="/*" element={<Main />} />
        </Routes>
        <Footer />         
      </BrowserRouter>}
    </div>
  );
}

export default App;
